import {TableBase} from "../../tables/table_base";

class SnapshotOverview extends TableBase {
  constructor () {
    super('snapshot_overview');
  }

  paintTable ($el, data) {
    for (const key in data) {
      const {value: value, list_url: list_url, report_url: report_url} = data[key]
      const $container = $(`span#snapshot_overview_${key}`)
      $container.html(value)

      if (list_url !== null) {
        $container.siblings('a.list-link').attr('href', list_url).removeClass('hidden').show();
      }
      if (report_url !== null) {
        $container.siblings('a.report-link').attr('href', report_url).removeClass('hidden').show();
      }
    }
  }
}

$(() => {
  if(app && app.DASHBOARD_CORE) {
    new SnapshotOverview();
  } else {
    console.warn('DashboardCore not initalized.');
  }
});
